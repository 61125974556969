<template>
  <div :class="$style.detail">
    <div :class="$style.box">
      <div :class="$style.left">
        <div :class="$style.leftBox">
          <p :class="$style.leftTitle">关系列表</p>
          <div :class="$style.search">
            <div :class="$style.searchItem">关系类型</div>
            <div :class="$style.searchItem">关系方向</div>
            <div :class="$style.searchItem">关系文化点</div>
          </div>
        </div>
        <div
          v-for="item in showData"
          :key="item.id"
          :class="[
            $style.content,
            active === item.id ? $style.active : null,
            active === item.id ? $style.radius : null,
          ]"
          @click="onChoosePoint(item)"
        >
          <div :class="$style.it" class="ellipsis-width" :title="item.relation_name">{{ item.relation_name }}</div>
          <div :class="$style.it">
            <i v-if="item.poc_relation_way === 0" :class="$style.icon" class="ri-arrow-right-fill" />
            <i v-if="item.poc_relation_way === 1" :class="$style.icon" class="ri-arrow-left-fill" />
            <i v-if="item.poc_relation_way === 2" :class="$style.icon" class="ri-arrow-left-right-line" />
          </div>
          <div :class="$style.it" class="ellipsis-width" :title="item.rel_poc.name">{{ item.rel_poc.name }}</div>
          <div v-if="active === item.id" :class="[$style.more, active === item.id ? $style.active : null]">
            <div :class="$style.timebox">
              <div :class="$style.txt">关系时间：</div>
              <div :class="$style.val">
                <p v-if="item.relation_time.length" :title="getTime(item)[0]" class="ellipsis-width">
                  <span :class="$style.trans">起</span>
                  {{ getTime(item)[0] }}
                </p>
                <p v-if="item.relation_time.length === 2" :title="getTime(item)[1]" class="ellipsis-width">
                  <span :class="$style.trans">终</span>
                  {{ getTime(item)[1] }}
                </p>
                <p v-if="item.relation_time_desc" :title="item.relation_time_desc" class="ellipsis-width">
                  {{ item.relation_time_desc || '无' }}
                </p>
              </div>
            </div>
            <div :class="$style.timebox">
              <div :class="$style.txt">关系位置：</div>
              <div :class="$style.val">
                <p class="ellipsis-width">
                  {{ item.location || '无' }}
                </p>
              </div>
            </div>
            <div :class="$style.timebox">
              <div :class="$style.txt">关系描述：</div>
              <div :class="$style.val">
                <p>
                  {{ item.relation_desc || '无' }}
                </p>
              </div>
            </div>
            <div :class="$style.btns">
              <i
                v-permission="'resources.poc.relation-edit'"
                class="ri-edit-2-line"
                :class="$style.edit"
                @click.stop="onEdit(item)"
              />
              <span @click.stop>
                <a-popconfirm title="确定要删除图谱关系吗？删除之后将不能再次找回。" @confirm="onDelete(item)">
                  <i
                    v-permission="'resources.poc.relation-delete'"
                    class="ri-delete-bin-7-line"
                    :class="$style.delete"
                  />
                </a-popconfirm>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div :class="$style.right">
        <RelationGraph
          ref="seeksRelationGraph"
          :options="graphOptions"
          :on-node-click="onNodeClick"
          :on-line-click="onLineClick"
        >
          <template #node="item">
            <div :class="$style.node" class="ellipsis-width">{{ item.node.text }}</div>
          </template>
        </RelationGraph>
      </div>
      <div v-permission="'resources.poc.relation-create'" :class="$style.addBtn" @click="onAdd()">
        <i class="ri-add-line" />
      </div>
    </div>
    <Add :id="edit.id" :edit="edit" :visible.sync="visible" @refresh="getDetail" />
  </div>
</template>

<script>
import RelationGraph from 'relation-graph'

import { relationService } from '@/service'

import Add from './Add.vue'

const service = new relationService()

export default {
  name: 'Detail',
  components: {
    RelationGraph,
    Add,
  },
  data() {
    return {
      visible: false,
      graphOptions: {
        backgrounImageNoRepeat: true,
        layouts: [
          {
            label: '中心',
            layoutName: 'force',
            layoutClassName: 'seeks-layout-center',
            distance_coefficient: 1.2,
          },
        ],
        defaultLineMarker: {
          markerWidth: 20,
          markerHeight: 20,
          refX: 3,
          refY: 3,
          data: 'M 0 0, V 6, L 4 3, Z',
        },
        allowShowMiniNameFilter: true,
        allowShowMiniToolBar: false,
        allowShowMiniView: false,
        isMoveByParentNode: false,
        hideNodeContentByZoom: false,
        defaultNodeFontColor: 'rgba(0, 0, 0, 1)',
        defaultNodeBorderWidth: 1,
        defaultLineWidth: 1,
        defaultShowLineLabel: true,
      },
      id: null,
      // params: {
      //   poc_relation_id: null,
      // },
      tree: [],
      data: [],
      showData: [],
      active: null,
      edit: {
        id: '',
      },
    }
  },
  created() {
    this.id = this.$route.params.id
    this.getDetail()
    this.getTree()
  },
  methods: {
    getTime(item) {
      if (item.relation_time.length) {
        if (item.relation_time.length === 1) {
          let tmp = item.relation_time[0]
          return [
            `${tmp.time_type === 0 ? '公元前' : ''}${tmp.date} ${tmp.time} (${
              tmp.accuracy === 0 ? '准确' : tmp.accuracy === 1 ? '大约' : '存疑'
            })`,
          ]
        }
        if (item.relation_time.length === 2) {
          let left = item.relation_time[0]
          let right = item.relation_time[1]
          return [
            `${left.time_type === 0 ? '公元前' : ''}${left.date} ${left.time} (${
              left.accuracy === 0 ? '准确' : left.accuracy === 1 ? '大约' : '存疑'
            })`,
            `${right.time_type === 0 ? '公元前' : ''}${right.date} ${right.time} (${
              right.accuracy === 0 ? '准确' : right.accuracy === 1 ? '大约' : '存疑'
            })`,
          ]
        }
      }
      return item.relation_time_desc ? '' : '无'
    },
    onChoosePoint(item) {
      if (this.active === item.id) {
        this.active = null
        return
      }
      this.active = item.id
    },
    onAdd() {
      this.visible = true
      this.edit = {
        id: '',
        left_poc_name: this.$route.query.name,
        left_poc_id: this.id,
      }
    },
    async onEdit(item) {
      try {
        const { data } = await service.info({
          id: item.id,
        })
        this.edit = data
        this.visible = true
      } catch (e) {}
    },
    async onDelete(item) {
      try {
        await service.delete({
          id: item.id,
        })
        this.$message.success('删除成功')
        this.getDetail()
      } catch (e) {}
    },
    // refresh(type) {
    //   this.showData = this.data.filter(item => {
    //     if (type === 2) {
    //       return item.poc_relation_id === this.params.poc_relation_id && item.poc_relation_way === 2
    //     } else {
    //       return item.poc_relation_id === this.params.poc_relation_id && item.poc_relation_way !== 2
    //     }
    //   })
    // },
    async getTree() {
      const { data } = await service.tree()
      this.tree = data
    },
    async getDetail() {
      try {
        const { data } = await service.detail({
          poc_id: this.id,
        })
        this.data = data
        this.showData = data
        this.showSeeksGraph()
      } catch (e) {}
    },
    showSeeksGraph() {
      let __graph_json_data = {
        rootId: 'a',
        nodes: [
          // // node配置选项：http://relation-graph.com/#/docs/node
          // // node支持通过插槽slot完全自定义，示例：http://relation-graph.com/#/demo/adv-slot
          // { id: 'a', text: 'A' },
        ],
        links: [
          // link配置选项：http://relation-graph.com/#/docs/link
          // { from: 'a', to: 'b', text: '关系1' },
        ],
      }
      if (!this.data.length) {
        this.$refs.seeksRelationGraph.setJsonData(__graph_json_data)
        return
      }
      __graph_json_data.nodes.push({
        id: this.data[0].main_poc.id,
        text: this.data[0].main_poc.name,
        html: `<div style="width: 60px;height: 60px;border-radius: 100%;"><img style="width: 60px;height: 60px;border-radius: 100%;object-fit: cover;"
        src="${this.data[0].main_poc.cover.cover}" /><p style="text-align: center;margin-top: 10px">${this.data[0].main_poc.name}</p></div>`,
      })
      this.data.map(item => {
        __graph_json_data.nodes.push({
          name: item.rel_poc.name,
          id: item.rel_poc.id,
          html: `<div style="width: 60px;height: 60px;border-radius: 100%;"><img style="width: 60px;height: 60px;border-radius: 100%;object-fit: cover;" src="${item.rel_poc.cover.cover}" />
          <p style="text-align: center;margin-top: 10px">${item.rel_poc.name}</p></div>`,
        })
        // 多个判断后期会加功能
        if (item.poc_relation_way === 0) {
          __graph_json_data.links.push({
            from: item.main_poc.id,
            to: item.rel_poc.id,
            text: item.relation_name,
          })
        }
        if (item.poc_relation_way === 1) {
          __graph_json_data.links.push({
            from: item.rel_poc.id,
            to: item.main_poc.id,
            text: item.relation_name,
          })
        }
        if (item.poc_relation_way === 2) {
          __graph_json_data.links.push({
            from: item.main_poc.id,
            to: item.rel_poc.id,
            text: item.relation_name,
          })
          __graph_json_data.links.push({
            from: item.rel_poc.id,
            to: item.main_poc.id,
            text: item.relation_name,
          })
        }
      })
      this.$refs.seeksRelationGraph.setJsonData(__graph_json_data, () => {
        // console.log(seeksRGGraph)
      })
    },
    onNodeClick() {
      // console.log('onNodeClick:', nodeObject)
      // this.showSeeksGraph()
    },
    onLineClick() {
      //lineObject
      // console.log('onLineClick:', lineObject)
    },
  },
}
</script>

<style lang="less" module>
.detail {
  height: calc(100% - 70px);
  // padding: 20px;
  :global {
    .ant-select {
      width: 100%;
    }
  }

  .box {
    position: relative;
    display: flex;
    height: calc(100vh - 152px);
    padding: 20px;
    background: #fff;
  }

  .left {
    width: 400px;
    min-height: 100%;
    overflow-y: auto;
    border: 1px solid #eee;

    &-box {
      margin-bottom: 14px;
      padding: 13px;
      box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.05);
    }

    &-title {
      margin-bottom: 14px;
      color: #000;
      font-size: 16px;
    }
  }

  .right {
    flex: 1;
    height: calc(100vh - 192px);
    overflow: hidden;
  }

  .add-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 999;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;

    i {
      color: #000;
      font-size: 20px;
    }
  }

  .search {
    display: flex;

    &-item {
      width: 33.3%;
      padding: 0 10px;
      color: #5c5c5c;
    }
  }

  .content {
    width: 372px;
    height: 40px;
    margin: 0 auto 10px;
    line-height: 40px;
    border-radius: 6px;
    cursor: pointer;

    .it {
      float: left;
      width: 33%;
      padding: 0 12px;

      .icon {
        float: left;
        font-size: 18px;
      }
    }

    .more {
      float: left;
      width: 100%;
      padding: 0 12px;
      font-size: 12px;
      border-radius: 0 0 6px 6px;

      .desc {
        display: -webkit-box;
        height: auto;
        overflow: hidden;
        line-height: 20px;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
      }
    }
  }

  .content:hover {
    background: #fafafa;
  }

  .active {
    background: #fafafa;
  }

  .node {
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    text-align: center;
  }

  .btns {
    font-size: 18px;

    i {
      margin-right: 20px;
    }

    .edit {
      color: #000;
    }

    .edit:hover {
      color: @primary-color;
    }

    .delete {
      color: #000;
    }

    .delete:hover {
      color: @error-color;
    }
  }
}

.radius {
  border-radius: 6px 6px 0 0 !important;
}

.timebox {
  display: flex;
  line-height: 30px;
  font-size: 13px;

  .txt {
    width: 65px;
  }

  .val {
    flex: 1;

    p {
      line-height: 30px !important;
      margin-bottom: 0;
    }
  }
}

.trans {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  color: #5c5c5c;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  border: 1px solid #eee;
  border-radius: 4px;
}
</style>
